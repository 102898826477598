import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {SubscriptionResponse} from "../response/subscription.response";
import {LifetimeSubscriptionResponse} from "../response/lifetime-subscription.response";
import {CreateSubscriptionRequest} from "../request/create-subscription.request";
import {CreateSubscriptionResponse} from "../response/create-subscription.response";
import {UpgradeSubscriptionResponse} from "../response/upgrade-subscription.response";
import {InvoiceResponse} from "../response/invoice.response";
import {BillingAddressResponse} from "../response/billing-address.response";
import {CreateBillingAddressRequest} from "../request/create-billing-address.request";
import {CalculateTaxResponse} from "../response/calculate-tax.response";
import {PayInvoiceResponse} from "../response/pay-invoice.response";

@Injectable({
  providedIn: 'root'
})
export class CountryApiService {


  constructor(private http: HttpClient) {
  }

  getCountry(){
    return firstValueFrom(this.http.get<{ip:string,country:string}>('https://api.country.is'));
  }

}
